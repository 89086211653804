import Vue from 'vue'


export async function getMotivosPQR({ state }) {
    await Vue.axios({
        method: 'GET',
        headers: { 'content-type': 'application/json' },
        url: `/pqrs/obtener-motivos`,
    })
        .then((response) => {
            state.tiposSolicitud = response.data.data
        })
        .catch((err) => {
            console.log(err)
        })
        .finally(()=>{})
}

export async function getInfoPRQS({ state }, payload) {
    state.infoSolicitudPQRS = null
    await Vue.axios({
        method: 'GET',
        headers: { 'content-type': 'application/json' },
        url: `/pqrs/${payload.nRadicado}`,
    })
        .then((response) => {
            state.infoSolicitudPQRS = response.data.data
        })
        .catch((err) => {
            console.log(err)
        })
        .finally(()=>{})
}

export async function getTipoDoc({ state }) {
    await Vue.axios({
        method: 'GET',
        headers: { 'content-type': 'application/json' },
        url: `/users/obtener-tipo-documentos`,
    })
        .then((response) => {
            state.tiposDoc = response.data.data
        })
        .catch((err) => {
            console.log(err)
        })
        .finally(()=>{})
}

export async function getDependenciasPQR({ state }) {
    await Vue.axios({
        method: 'GET',
        headers: { 'content-type': 'application/json' },
        url: `/pqrs/obtener-dependencias`,
    })
        .then((response) => {
            state.dependenciasSolicitud = response.data.data
        })
        .catch((err) => {
            console.log(err)
        })
        .finally(()=>{})
}

export async function sendPQRQuac({ state }, payload) {
    console.log(state);
    let data = new FormData();
    for (let i = 0; i < payload.inputsDataFiles.length; i++) {
        data.append('archivos[]', payload.inputsDataFiles[i].data[0]);
    }
    data.append('tipo_documento', payload.tipoDoc);
    data.append('numero_documento', payload.document);
    data.append('nombres', payload.name);
    data.append('apellidos', payload.lastName);
    data.append('direccion', payload.direccion);
    data.append('ciudad', payload.ciudad);
    data.append('celular', payload.celular);
    data.append('correo_electronico', payload.email);
    data.append('tipo_pqr_id', payload.tipoSoli);
    data.append('dependencia_id', payload.dependenciaSoli);
    data.append('mensaje', payload.descripcionSoli);
    data.append('uuid', payload.uuid);

    await Vue.axios({
        method: 'POST',
        headers: {
            'content-type': 'multipart/form-data',
            'Accept': 'application/json'
        },
        url: `/pqrs`,
        data: data,
    })
        .then((response) => {
            state.summaryPQRS = response.data.message
        }).catch((e) => {
            console.log(e)
        }).finally(() => {
            console.log("End fetch")
        })
}
